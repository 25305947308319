@charset 'UTF-8';
@import "/Users/naomi/Sites/saga-local/src/scss/utility/_flex.scss";
@import "/Users/naomi/Sites/saga-local/src/scss/utility/_media-queries.scss";
@import "/Users/naomi/Sites/saga-local/src/scss/utility/_mixin.scss";


#page404 {
	.taC { display: block; font-size: 20px; line-height: 30px; letter-spacing: 3px; text-align: center; margin: 20px 0 0 0; 
		a { font-size: 20px;text-decoration: none; color: #00B0FF;  }
	}
	.page-title { font-size: 50px; font-weight: bold; letter-spacing: 5px; padding-bottom: 50px; color: #00B0FF;}
}
.page_404 { padding: 200px 20px; text-align: center;}

@include SP{
	#page404{
		.page_404{
			padding: 100px 20px;
		}
		.taC{
			font-size: 17px;
		}
	}
}